import React, { createElement } from "react"

import { sprinkles } from "@app/shared/design-lib/style-theme/sprinkles.css"
import theme from "@app/shared/design-lib/style-theme/theme.css"

import { typographyVariants } from "./styles.css"
import { TokenSupport } from "./TokenSupport"

export type Style =
  | "display"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "bodyL"
  | "bodyM"
  | "description"
  | "caption"
  | "smallText"
type Alignment = "left" | "center" | "right" | "inherit"
type HtmlElement = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "div"

interface Props {
  alignment?: Alignment
  className?: string
  color?: string
  component?: HtmlElement
  onClick?: () => void
  variant?: Style
  withMargin?: boolean
}

interface Props {
  alignment?: Alignment
  children?: React.ReactNode
  className?: string
  color?: string
  component?: HtmlElement
  onClick?: () => void
  variant?: Style
  withMargin?: boolean
}
export const Typography: React.FC<Props> = ({
  alignment = "inherit",
  children,
  className,
  color = theme.colors.greyscale600,
  component = "p",
  variant = "bodyM",
  withMargin = false,
  ...rest
}) => {
  const token = createElement(TokenSupport, { children })
  return createElement(component, {
    children: token,
    className: `${typographyVariants[variant]} ${sprinkles({
      marginBottom: withMargin ? "1em" : 0,
      textAlign: alignment,
    })} ${className || ""}`,
    style: { color },
    ...rest,
  })
}
