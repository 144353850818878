import { NextSeo } from "next-seo"
import { OpenGraph } from "next-seo/lib/types"
import React from "react"

import { LanguageAlternate } from "@app/shared/types/pages"

export type SeoProps = {
  alternates?: LanguageAlternate[]
  canonical?: string
  description: string
  nofollow?: boolean
  noindex?: boolean
  norobots?: boolean
  og?: OpenGraph
  title: string
}

export const Seo: React.FC<SeoProps> = ({
  alternates,
  canonical,
  description,
  nofollow,
  noindex,
  og = {},
  title,
}) => {
  const _og = Object.assign({ description, nofollow, noindex, title }, og)

  return (
    <NextSeo
      canonical={canonical}
      description={description}
      languageAlternates={alternates}
      nofollow={nofollow}
      noindex={noindex}
      openGraph={{ ..._og }}
      title={title}
      twitter={{ cardType: "summary_large_image" }}
    />
  )
}
