import Trans from 'next-translate/Trans'
import { Children, memo, PropsWithChildren } from 'react'

const defaultComponents = {
  b: <strong style={{ fontWeight: 700 }} />,
  br: <br />,
  i: <em />,
}

export type ComponentMap = Record<string, React.ReactNode>

export const TokenSupport: React.FC<PropsWithChildren<{ components?: ComponentMap }>> = memo(function TokenSupport({
  children,
  components,
}) {
  return <>
    {Children.map(children, function (child) {
      if (typeof child === 'string') {
        return (
          <Trans
            components={{
              ...defaultComponents,
              ...components,
            }}
            i18nKey={child.replace(/\n/g, '<br/>')}
          ></Trans>
        );
      }
      return child
    })}
  </>;
})
