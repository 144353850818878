import getConfig from "next/config"
import Image, { ImageLoader, ImageProps } from "next/image"
import React from "react"

export const MyImage: React.FC<ImageProps> = (props) => {
  return <Image loader={defaultLoader} {...props} alt={props?.alt || "image description"} />
}

const defaultLoader: ImageLoader = ({ src }) => {
  const {
    publicRuntimeConfig: { imageBasePath, imgixDefaultOptions },
  } = getConfig()

  return `${imageBasePath}${src}?${imgixDefaultOptions}`
}
