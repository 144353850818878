export const ChevronRight: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    fill="currentColor"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.41421 0.292847L13.1213 7.99995L5.41421 15.7071L4 14.2928L10.2921 7.99995L4 1.70706L5.41421 0.292847Z"
      fillRule="evenodd"
    />
  </svg>
)
